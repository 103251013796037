<template>
  <tr>
    <td>
      <v-select
        v-model="cdrfilterItem.city__code"
        :items="city_info"
        outlined
        dense
        clearable
        @change="getDistrictApi"
      ></v-select>
    </td>
    <td class="px-2">
      <v-select
        v-model="cdrfilterItem.district__code"
        :items="district_info"
        outlined
        dense
        clearable
        @change="getRegionApi"
        :item-text="(item) => item.name + '(' + item.code + ')'"
        item-value="code"
      ></v-select>
    </td>
    <td>
      <v-select
        v-model="cdrfilterItem.region__code"
        :items="region_info"
        outlined
        dense
        clearable
        :item-text="(item) => item.name + '(' + item.code + ')'"
        item-value="code"
        @change="onChange"
      ></v-select>
    </td>
    <slot></slot>
  </tr>
</template>

<script>
import { mapState } from "vuex";
import { city_info } from "@/definition.js";
export default {
  data: (vm) => ({
    city_info: city_info,
    cdrfilterItem: {
      city__code: "H",
      district__code: null,
      region__code: null,
    },
    district_info: [],
    region_info: [],
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    onChangeFilter(filterItem){
      
      delete filterItem.city__code
      delete filterItem.district__code
      delete filterItem.region__code

      let merged = {...this.cdrfilterItem, ...filterItem};
      this.$emit("onCDRFilterChange", merged);
    },
    onChange() {
      if (
        this.cdrfilterItem.city__code == null ||
        this.cdrfilterItem.district__code == null
      ) {
        return;
      }
      this.$emit("onCDRFilterChange", this.cdrfilterItem);
    },
    getDistrictApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/districts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {},
      };
      config.params["city__code"] = this.cdrfilterItem.city__code;

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.district_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getRegionApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/regions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city__code: this.cdrfilterItem.city__code,
          district__code: this.cdrfilterItem.district__code,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.region_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
