var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"Check-base","fluid":"","tag":"section"}},[_c('v-dialog',{attrs:{"max-width":"80%"},on:{"click:outside":_vm.close},model:{value:(_vm.transcript_show),callback:function ($$v) {_vm.transcript_show=$$v},expression:"transcript_show"}},[_c('v-card',[_c('json-viewer',{attrs:{"value":_vm.transcript_json,"expand-depth":5,"copyable":""}})],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"Land Profile"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":""},on:{"change":_vm.callFilterApply},model:{value:(_vm.filterItem.lbkey),callback:function ($$v) {_vm.$set(_vm.filterItem, "lbkey", $$v)},expression:"filterItem.lbkey"}})],1)])]},proxy:true},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getError(item.error)))]}},{key:"item.owners_json",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-textarea',{attrs:{"label":"Edit"},model:{value:(item.owners_json),callback:function ($$v) {_vm.$set(item, "owners_json", $$v)},expression:"item.owners_json"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.owners_json)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":""},on:{"save":function($event){return _vm.updateStatus(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',{attrs:{"align":"baseline"}},[_c('v-select',{attrs:{"label":_vm.$t('status'),"items":_vm.status_items,"large":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("getText")(item.status,_vm.status_items))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.setErrorJson(item)}}},on),[_vm._v("mdi-eye-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view error")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getToken(item.lbkey)}}},on),[_vm._v("mdi-file")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view tp")))])])]}},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.verify(item)}}},on),[_vm._v("mdi-air-filter")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view error")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }