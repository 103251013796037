<script>
import CheckBase from "./CheckBase";
export default {
  data: (vm) => ({
    base_url: "/backend/landverify/",
    profile_url: "/backend/lands/",
    mongo_url: "/mongo/land_transcripts/",
    export_url:"/backend/lands/export/",
  }),
  extends: CheckBase,
};
</script>