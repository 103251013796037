<template>
  <v-container id="Check-base" fluid tag="section">
    <v-dialog v-model="transcript_show" max-width="80%" @click:outside="close">
      <v-card>
        <json-viewer
          :value="transcript_json"
          :expand-depth="5"
          copyable
        ></json-viewer>
      </v-card>
    </v-dialog>
    <base-material-card
      icon="mdi-clipboard-text"
      title="Land Profile"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :loading="loading"
        dense
      >
        <template v-slot:header>
          <tr>
            <td>
              <v-text-field
                v-model="filterItem.lbkey"
                outlined
                dense
                clearable
                @change="callFilterApply"
              ></v-text-field>
            </td>
          </tr>
        </template>
        <!-- data -->
        <template v-slot:item.error="{ item }">{{
          getError(item.error)
        }}</template>

        <template v-slot:item.owners_json="{ item }">
          <v-edit-dialog large>
            {{ item.owners_json }}
            <template v-slot:input>
              <v-textarea v-model="item.owners_json" label="Edit"> </v-textarea>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.status="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(status_items) }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="status_items"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="setErrorJson(item)"
                >mdi-eye-outline</v-icon
              >
            </template>
            <span>{{ $t("view error") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="getToken(item.lbkey)"
                >mdi-file</v-icon
              >
            </template>
            <span>{{ $t("view tp") }}</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="getOwnerJson(item)"
                >mdi-chevron-double-right</v-icon
              >
            </template>
            <span>{{ $t("get owner json") }}</span>
          </v-tooltip> -->
        </template>

        <template v-slot:item.actions2="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="verify(item)"
                >mdi-air-filter</v-icon
              >
            </template>
            <span>{{ $t("view error") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import JsonViewer from "vue-json-viewer";
import CDRSelectFilter from "../filters/CDRSelectFilter";
import { mapState, mapMutations, mapActions } from "vuex";
import { city_info, status_items } from "@/definition.js";
export default {
  data: (vm) => ({
    dialog_form: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    default_item: {
      city__code: "H",
      district__code: null,
      region__code: null,
      lbkey: null,
      owners_num: null,
      creditors_num: null,
      c_add_private: null,
      c_add_p_num: null,
    },
    filterItem: {
      city__code: "H",
      district__code: null,
      region__code: null,
      lbkey: null,
      owners_num: null,
      creditors_num: null,
      c_add_private: null,
      c_add_p_num: null,
    },
    editedIndex: -1,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
        width: "15%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("error"),
        value: "error",
        width: "30%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions2"),
        value: "actions2",
      },
    ],
    status_items: status_items,
    city_info: city_info,
    district_info: [],
    region_info: [],

    transcript_show: false,
    transcript_json: null,
  }),
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  components: {
    CDRSelectFilter,
    JsonViewer,
  },
  computed: {
    ...mapState(["city", "district", "region", "token"]),
  },
  methods: {
    ...mapMutations(["setCity", "setRegion"]),
    getCDRName(item) {
      return item.city.name + "/" + item.district.name + "/" + item.region.name;
    },
    getJson(item) {
      return JSON.stringify(item.owners_json);
    },
    getError(data) {
      // if (data != null && "o_json_err" in data) {
      //   // console.log("ok...fail ")
      //   if ("values_changed" in data["o_json_err"]) {
      //     console.log("value changed..");
      //     return data;
      //   }
      // }

      return data;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + this.base_url;
      console.log("url", url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      for (const [key, value] of Object.entries(this.filterItem)) {
        if (!this.$common_util.isEmpty(value)) {
          config.params[key] = value;
        }
      }
      config.params["status__lt"] = 9;
      // get some fields
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    postApi() {},
    getList(data) {
      return data;
    },
    getRegNo(data) {
      if (data === null || data === "") {
        return data;
      }
      let parseObj = JSON.parse(data.replace(/'/g, '"'));
      console.log(parseObj);
      let result = "";
      for (let i = 0; i < parseObj.length; i++) {
        result += parseObj[i] + ";";
      }

      return result;
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
      const currentObj = this;
    },
    onCDRFilterChange(item) {
      this.filterItem = this._.cloneDeep(item);
      this.getApi();
    },
    getTranscriptJson(item, set_result_func = this.setTranscriptResult) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + this.mongo_url + item.transcript + "/";
      console.log("url " + url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          set_result_func(response.data);
          currentObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setErrorJson(item) {
      this.setTranscriptResult(item.error);
    },
    setTranscriptResult(data) {
      this.transcript_json = data;
      this.transcript_show = true;
    },
    close() {
      this.transcript_show = false;
    },
    callFilterApply() {
      this.getApi();
    },

    reset() {
      this.filterItem = this._.cloneDeep(this.default_item);
      this.getApi();
    },
    getOwnerJson(item) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + this.profile_url + item.lbkey + "/";
      console.log("url " + url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          query: "{ id, lbkey,transcript}",
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          // set_result_func(response.data);
          currentObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getToken(lbkey) {
      // this.loading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + this.profile_url + lbkey + "/";
      console.log("get token url", url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        query: "{ id, lbkey,owners_json,}",
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log("done...");
          console.log(response.data);
          // currentObj.loading = false;
          currentObj.getTranscriptJson(response.data);
          // currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    verify(item) {
      const url =process.env.VUE_APP_SERVER_URL + this.base_url + item.lbkey + "/verify/";
      console.log("url " + url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },

      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          // set_result_func(response.data);
          currentObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
  mounted() {
    // this.$options.sockets.onmessage = this.receiveSocketData;
  },
};
</script>
